import { Global, Collection } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../../components/SeoHead';
import MainBanner from '../../components/MainBanner';
import GetInTouch from '../../components/GetInTouch';
import AccordionColumnBox from '../../components/AccordionColumnBox';

export default function AuPage({
    global,
    all_contractor_benefits
}) {
    return (
        <>
            <SeoHead
                page_name={`AU | The global house of tech and transformation recruitment | Lorien`}
                canonical_link={`AU`}
            />
            <MainBanner
                global={global}
                pathPrefix={`au.page.main.banner`}
                customClass={``}
                content={
                    <>
                        <h1>Contractors Test</h1>
                    </>
                }
                imageURL1={`/site-assets/images/main-banner-v2.png`}
                imageALT1={`Main Banner`}
                imageURL2={`/site-assets/images/main-banner-overlay-v2.png`}
                imageALT2={`Overlay Main Banner`}
            />
            <AccordionColumnBox
                global={global}
                pathPrefix={`au_page.accordion_column_box`}
                customClass={`pt-5 grayGradientBg`}
                content={
                    <>
                        <h2>Contractor Benefits</h2>
                    </>
                }
                post={all_contractor_benefits}
            />
            <GetInTouch
                global={global}
                pathPrefix={`au_page.get_in_touch`}
                customClass={`py-5`}
                content={
                    <>
                        <h2>Get in ouch!</h2>
                        <p>Unresolved query? Let us help. Get in touch with the Lorien Contractor and Candidate Support Team to find answers quickly, from information on payments to how to submit your timesheets. No matter how big or small the question, no matter how new or experienced you are to the world of contracting, we'll do our best to give you up-to-date, informed, and helpful answers about working through Lorien as a contractor.</p>
                        <br></br>
                        <h5>Our hotlines are:</h5>
                    </>
                }
                content_2={``}
                buttonLink={``}
                buttonTitle={``}
                content_3={
                    <>
                        <p>For all other enquiries, please complete our form or reach out to us on <a href="tel:02039109446">02039109446</a>. </p>
                    </>
                }
                formId={`59ab1381-495d-4c89-b5fb-f2f3dc400a3e`}
                successPageUrl={`/au/submission-success/`}
            />
        </>
    )
}

export async function getStaticProps({ params }) {
    const global = (await Global()).toJson();

    const contractor_benefits = (await Collection('contractor_benefits'))
		.filter(function (post) {
			return (
				post.country_tag?.en.includes("AU")
			);
		});

    const all_contractor_benefits = contractor_benefits.orderBy('_order').getItems();
    return {
        props: {
            global,
            all_contractor_benefits
        }
    };
}
